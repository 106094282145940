import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import SearchIcon from "@mui/icons-material/Search";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { AccountBoxOutlined, Logout, Settings } from "@mui/icons-material";
import { Link, useLocation } from "react-router-dom";

import "../../styles/appbar/appbar.css";
import logo from "../../assets/images/Logo.png";
import ligth from "../../assets/icons/ligth-icon.svg";
import dark from "../../assets/icons/dark-icon.svg";
import filter_icon from "../../assets/icons/filter-icon.svg";
import notif_icon from "../../assets/icons/notification-icon.svg";
import arrow_down from "../../assets/icons/arrow-down.svg";
import NotificationBox from "../notification/NotificationsBox";

export default function Appbar({ filterOpen, setFilterOpen }) {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [showNotifs, setShowNotifs] = React.useState(false);
  const [renderSearchInput, setRenderSearchInput] = React.useState(false);
  const path = useLocation().pathname;

  const renderSearchimput = () => {
    if (path.startsWith("/bounties") || path.startsWith("/dashboard")) {
      setRenderSearchInput(false);
    } else {
      setRenderSearchInput(true);
    }
  };

  React.useEffect(() => {
    renderSearchimput();
  });

  const handleShowNotif = () => {
    setShowNotifs(!showNotifs);
    isMenuOpen && setIsMenuOpen(false);
  };
  const handleMenuOpen = () => {
    setIsMenuOpen(!isMenuOpen);
    showNotifs && setShowNotifs(false);
  };

  const renderMenu = (
    <Box className="menu-popup" sx={{ display: isMenuOpen ? "block" : "none" }}>
      <MenuItem>
        <Box display="flex">
          <IconButton className="user-profil-picture">
            <AccountBoxOutlined color="#C4B7A7" />
          </IconButton>
          <Box display="flex" flexDirection="column" sx={{ ml: 2 }}>
            <Typography variant="body1">David Adegoke</Typography>
            <Typography variant="body2">davidboostupnow@gmail.com</Typography>
          </Box>
        </Box>
      </MenuItem>
      <MenuItem sx={{ color: "#717575", width: "100%" }}>
        <IconButton size="large" aria-label="show 4 new mails">
          <Settings />
        </IconButton>
        <p>Settings</p>
      </MenuItem>
      <MenuItem sx={{ color: "#717575", width: "100%" }}>
        <IconButton size="large" aria-label="show 17 new notifications">
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
      <Link to={"/login"}>
        <MenuItem sx={{ width: "100%" }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
          >
            <Logout />
          </IconButton>
          <p>Sign out</p>
        </MenuItem>
      </Link>
    </Box>
  );

  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        sx={{
          left: { sm: 0, md: "90px" },
          width: { sm: "100%", md: "calc(100% - 90px)" },
          bgcolor: "white",
        }}
        className="appbar"
        position="fixed"
        color="tranparent"
      >
        <Toolbar className="toolbar">
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              display: { xs: "block", lg: "none" },
              mr: 2,
            }}
          >
            <Link to={"/"}>
              <img src={logo} width={30} height={30} alt="logo" />
            </Link>
          </Typography>
          <Box className="mode-control">
            <img src={ligth} alt="ligth" />
            <Box className="divider" />
            <img src={dark} alt="dark" />
          </Box>
          {renderSearchInput && (
            <Box className="search-container">
              <SearchIcon color="#717575" />
              <InputBase
                className="search-input"
                placeholder="Search by Project or Username"
                inputProps={{ "aria-label": "search" }}
                sx={{ flexGrow: 1 }}
              />
              <IconButton
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              >
                <img src={filter_icon} alt="filter-icon" />
              </IconButton>
            </Box>
          )}
          <Box sx={{ flexGrow: 1 }} />
          <Box display="flex" className="nav-actions">
            <IconButton
              size="large"
              aria-label="show 17 new notifications"
              sx={{ mr: 2 }}
              onClick={handleShowNotif}
            >
              <img src={notif_icon} alt="notifications" />
            </IconButton>
            <Box position="relative">
              <Box
                position="relative"
                display="flex"
                alignItems="center"
                onClick={handleMenuOpen}
              >
                <IconButton
                  size="large"
                  edge="end"
                  aria-label="account of current user"
                  aria-haspopup="true"
                  className="user-profil-picture"
                >
                  <AccountBoxOutlined color="#C4B7A7" />
                </IconButton>
                <Box
                  display="flex"
                  flexDirection="column"
                  sx={{ display: { xs: "none", md: "flex" }, mx: 2 }}
                >
                  <Typography variant="body1">David Adegoke</Typography>
                  <Typography variant="body2">
                    davidboostupnow@gmail.com
                  </Typography>
                </Box>
                <Box display={{ xs: "none", lg: "block" }}>
                  <img
                    src={arrow_down}
                    alt="arrow-down"
                    width={16}
                    height={6}
                  />
                </Box>
              </Box>
              {renderMenu}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {showNotifs && <NotificationBox />}
    </Box>
  );
}
