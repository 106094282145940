import React from "react";
import {
  Box
} from "@mui/material";

import "../../styles/auth/signup.css";
import SignupForm from "../../componants/singnupForm";
import HackerImage from "../../assets/images/cyber-criminals-team-hacking-computer-system-coding-malicious-software.png"


const SignUp = () => {

  return (
    <Box className="signup-container">
      <SignupForm page="hacker_signup" subtitle="Join our ethical hacker community to access programs, earn
              bounties, and showcase your skills." />
      <Box className="signup-img" sx={{ backgroundImage: `url(${HackerImage})`,}} />
    </Box>
  );
};

export default SignUp;
