import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import HackerLayout from "../../componants/layout/HackerLayout";
import "../../styles/dashboard.css";
import AccountComponant from "../../componants/vault/AccountComponant";
import KycComponant from "../../componants/vault/KycComponant";
import { ReactComponent as WalletIcon } from "../../assets/icons/wallet-icon.svg";
import { ReactComponent as VaultIcon } from "../../assets/icons/vault-icon.svg";
import TransactionTable from "../../componants/vault/TransactionTable";

const VaultPage = () => {
  return (
    <HackerLayout>
      {({ filterOpen, setFilterOpen }) => (
        <Box className="vault-page" px={3}>
          <Box className="page-title" display="flex" mb={5}>
            <VaultIcon alt="wallet-icon" />
            <Typography
              variant="h3"
              ml={1}
              sx={{ fontWeight: 600, fontSize: "16px" }}
            >
              Vault
            </Typography>
          </Box>
          <Box
            className="balance-card header-balance-card balance-light"
            bgcolor={""}
          >
            <WalletIcon alt="wallet-icon" />
            <span>
              <Typography className="balance-title">Wallet Balance</Typography>
              <Typography className="balance-value">$27,005.43</Typography>
            </span>
          </Box>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 6, lg: 7 }}>
              <AccountComponant />
            </Grid>

            <Grid size={{ xs: 12, md: 6, lg: 5 }}>
              <KycComponant />
            </Grid>
          </Grid>
          <TransactionTable/>
        </Box>
      )}
    </HackerLayout>
  );
};

export default VaultPage;
