import React from "react";
import { Box, Card, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import "../../styles/discoverypage.css";
import HackerLayout from "../../componants/layout/HackerLayout";
import DiscoveryCard from "../../componants/cards/DiscoveryCard";
import FilterComponant from "../../componants/filter/FilterComponent";
import BountyCard from "../../componants/cards/BountyCard";

import discovery_icon from "../../assets/icons/discovery-icon.svg";
import slack_icon from "../../assets/icons/slack-icon.svg";
import g_icon from "../../assets/icons/g-icon.svg";
import salesforce_icon from "../../assets/icons/salesforce-icon.svg";
import arrow_rigth from "../../assets/icons/arrow-rigth.svg";

const DiscoveryPage = () => {
  return (
    <HackerLayout>
      {({ filterOpen, setFilterOpen }) => (
        <Box px={2}>
          {filterOpen && <FilterComponant />}
          <Card className="discoveries">
            <Box className="discoveries-head">
              <Box>
                <img src={discovery_icon} alt="discovery" />
                <Typography>Discovery </Typography>
              </Box>
              <Box>
                <Typography mr={1}>view more</Typography>
                <img src={arrow_rigth} alt="arrow_rigth" />
              </Box>
            </Box>
            <Grid className="discoveries-items" container spacing={2}>
              <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                <DiscoveryCard
                  icon={slack_icon}
                  title=" Slack Bounty Hack"
                  description="Lorem ipsum dolor sit amet consectetur. Sit ultricies nulla aliquet amet
        sagittis mi tristique facilisi..."
                  minPrice="$15"
                  maxPrice="$75k"
                  tags={["Domain", "Wildcard"]}
                  users={20}
                  fork={129}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                <DiscoveryCard
                  icon={g_icon}
                  title=" Spoofing Hack"
                  description="Lorem ipsum dolor sit amet consectetur. Sit ultricies nulla aliquet amet
        sagittis mi tristique facilisi..."
                  minPrice="$20"
                  maxPrice="$50k"
                  tags={["Wildcard"]}
                  users={20}
                  fork={129}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6, lg: 4 }}>
                <DiscoveryCard
                  icon={salesforce_icon}
                  title=" Slack Bounty Hack"
                  description="Lorem ipsum dolor sit amet consectetur. Sit ultricies nulla aliquet amet
        sagittis mi tristique facilisi..."
                  minPrice="$33"
                  maxPrice="$67k"
                  tags={["Wildcard", "Domain"]}
                  users={20}
                  fork={129}
                />
              </Grid>
            </Grid>
          </Card>
          <Card className="discoveries bounties">
            <Box className="discoveries-head">
              <Box>
                <img src={g_icon} alt="bounty" />
                <Typography>Bounties by Technologies </Typography>
              </Box>
              <Box>
                <Typography mr={1}>view more</Typography>
                <img src={arrow_rigth} alt="arrow_rigth" />
              </Box>
            </Box>
            <Grid className="discoveries-items" container spacing={2}>
              {[1, 2, 3, 4, 5, 6].map((elm, index) => (
                <Grid key={index} size={{ xs: 6, md: 4, lg: 2 }}>
                  <BountyCard
                    title="Microsoft"
                    icon={index % 2 === 0 ? salesforce_icon : slack_icon}
                    progress={index % 2 === 0 ? "increase" : "decrease"}
                    id="#321"
                  />
                </Grid>
              ))}
            </Grid>
          </Card>
        </Box>
      )}
    </HackerLayout>
  );
};

export default DiscoveryPage;
