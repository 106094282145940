import { Box, Button, Card, Divider, Typography } from "@mui/material";

import "../../styles/components/discoverycard.css";
import { FavoriteBorderOutlined } from "@mui/icons-material";

import user_group from "../../assets/icons/user-group.svg";
import fork_icon from "../../assets/icons/fork-icon.svg";
import { ReactComponent as ExploreIcon } from "../../assets/icons/explore-icon.svg";
import { Link } from "react-router-dom";

export default function DiscoveryCard({
  icon,
  title,
  minPrice,
  maxPrice,
  users,
  fork,
  description,
  tags,
}) {
  return (
    <Card className="discovery">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="icon-container">
          <img src={icon} alt="icon" />
        </Box>
        <Typography
          sx={{
            fontWeight: 600,
            color: "#57A885",
          }}
        >
          {minPrice} - {maxPrice}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography
          sx={{
            fontWeight: 600,
            color: "#333333",
          }}
        >
          {title}
        </Typography>
        <Box className="favorite-icon">
          <FavoriteBorderOutlined />
        </Box>
      </Box>
      <Typography variant="body1" className="description">
        {description}
      </Typography>

      <Box display="flex" justifyContent="start" alignItems="center">
        <Box className="mini-box" my={1}>
          <img src={user_group} alt="user group icon" width={13} height={13} />
          <Typography variant="body2">{users}</Typography>
        </Box>
        <Box className="mini-box">
          <img src={fork_icon} alt="fork icon" width={13} height={13} />
          <Typography variant="body2">{fork}</Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="start" alignItems="center">
        {tags?.map((tag, index) => (
          <Box key={index} className="mini-box" my={1}>
            <Typography variant="body2">{tag}</Typography>
          </Box>
        ))}
      </Box>
      <Divider sx={{ my: 1 }} />
      <Link to={`/bounties/${title}`} width="100% !important">
        <Button variant="outlined" className="explore-btn">
          <Typography variant="body2">Explore</Typography>
          <ExploreIcon alt="expore icon" width={13} height={13} />
        </Button>
      </Link>
    </Card>
  );
}
