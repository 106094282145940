import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  InputAdornment,
  IconButton,
  Divider,
  OutlinedInput,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import "../styles/auth/signup.css";
import logo from "../assets/images/Logo.png";
import { Link } from "react-router-dom";

const LoginForm = () => {
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Box className="signup-content">
      <Box className="signup-box">
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }} className="signup-header">
            <img src={logo} alt="logo" className="logo" />
            <Box className="signup-heading-divider">
              <Typography variant="body2" className="signup-title">
                LOGIN
              </Typography>
              <Divider className="divider" />
            </Box>
            <Typography variant="h4" className="signup-heading">
              Initiate Session
            </Typography>
            <Typography variant="body1" className="signup-subtitle">
              Enter the network, access programs, and start making an impact.
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}></Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <label>Email Address</label>
            <OutlinedInput
              fullWidth
              placeholder="Email address"
              type="email"
              className="signup-input"
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}></Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <label>Password</label>
            <OutlinedInput
              fullWidth
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="signup-input"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            <Box className="reset-password">
              <Link to={"#"} variant="body2" className="signup-terms">
                Reset Credentials?
              </Link>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}></Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className="signup-button"
            >
              Hack Your Way In!
            </Button>
            <Divider className="signup-divider">
              <Typography variant="body2" color="textSecondary">
                Or
              </Typography>
            </Divider>
            <Link to={"/signup-company"}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                className="signup-secondary-button"
                sx={{ textTransform: "none !important" }}
              >
                Create Account
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default LoginForm;
