import { Avatar, AvatarGroup, Box, Button, Typography } from "@mui/material";
import { FavoriteBorderOutlined } from "@mui/icons-material";

import { ReactComponent as SlackIcon } from "../../assets/icons/slack-icon.svg";
import { ReactComponent as InterestIcon } from "../../assets/icons/interest-icon.svg";
import user_icon from "../../assets/icons/user_default.png";


export default function BountyGlobalInfo({title}){
    return(
        <Box className="bounty-infos">
        <Box className=" bounty-detail-img" />
        <Box className="bounty-info-container">
          <SlackIcon alt="bounty-icon" width={70} height={70} />
          <Box flexGrow={1} ml={{ xs: 0, md: 2 }}>
            <Box display="flex" justifyContent="space-between">
              <Typography
                sx={{
                  fontWeight: 600,
                }}
              >
                {title}
              </Typography>
              <Box ml={2} className="favorite-icon">
                <FavoriteBorderOutlined color="white" />
              </Box>
            </Box>
            <Box className="attributes-display">
              <Box>
                <Typography className="attribute-name">
                  Reward Range:
                </Typography>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#57A885",
                  }}
                >
                  $50 - $20K
                </Typography>
              </Box>
              <Box>
                <Typography className="attribute-name">
                  Reward Type:
                </Typography>
                <Typography>Gift</Typography>
              </Box>
              <Box>
                <Typography className="attribute-name">
                  Launch Date
                </Typography>
                <Typography>16-12-2024</Typography>
              </Box>
              <Box>
                <Typography className="attribute-name">Website</Typography>
                <a href="https://teklabspace.com/">
                  https://teklabspace.com/
                </a>
              </Box>
              <Box>
                <Typography className="attribute-name">
                  Hackers in project:
                </Typography>
                <AvatarGroup total={200} className="avatar-group">
                  <Avatar
                    alt="Remy Sharp"
                    src={user_icon}
                    className="avatar"
                  />
                  <Avatar alt="Travis Howard" src={user_icon} />
                  <Avatar alt="Agnes Walker" src={user_icon} />
                  <Avatar alt="Trevor Henderson" src={user_icon} />
                </AvatarGroup>
              </Box>
              <Button variant="outlined" className={`interest-btn`}>
                Send Interest 
                <InterestIcon  alt="interest-icon" />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    )
}