import React from 'react';
import { Box, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import dayjs from 'dayjs';

import '../../styles/components/bounty/reportlist.css';
import { ReactComponent as ReportIcon } from "../../assets/icons/repport-icon.svg";

const ReportListItem = ({ title, submissionDate }) => {
  return (
    <Box className="report-list-item">
      <Box className="report-item-left">
        <ReportIcon alt="report-icon" className="report-icon" />
        <Typography variant="body1" className="report-title">
          {title}
        </Typography>
      </Box>
      <Box className="report-item-right">
        <Typography variant="caption" className="report-date">
          Submitted: {dayjs(submissionDate).format('DD/MM/YYYY')}
        </Typography>
        <KeyboardArrowDownIcon className="report-icon" />
      </Box>
    </Box>
  );
};

export default ReportListItem;