import { Box } from "@mui/material";
import Grid from "@mui/material/Grid2";

import HackerLayout from "../../componants/layout/HackerLayout";
import RankingComponant from "../../componants/leaderboard/RankingComponant";
import HighestRanking from "../../componants/leaderboard/HighestRanking";

const Leaderboard = () => {
  return (
    <HackerLayout>
      {({ filterOpen, setFilterOpen }) => (
        <Box px={3}>
          <Grid container spacing={2}>
            <Grid size={{ xs: 12, md: 8, lg: 8 }}>
              <RankingComponant />
            </Grid>
            <Grid size={{ xs: 12, md: 4, lg: 4 }}>
              <HighestRanking />
            </Grid>
          </Grid>
        </Box>
      )}
    </HackerLayout>
  );
};

export default Leaderboard;
