import React from "react";
import { Box } from "@mui/material";

import "../../styles/auth/signup.css";
import SignupForm from "../../componants/singnupForm";
import CompanyImage from "../../assets/images/cybersecurity-specialist-operates-high-tech-military-control-center.png"

const SignupCompany = () => {
  return (
    <Box className="signup-container">
      <SignupForm
        page="company_signup"
        subtitle="Create your company profile to submit projects, earn rewards, and improve the web through bug hunting."
      />
      <Box className="signup-img" sx={{backgroundImage: `url(${CompanyImage})`}} />
    </Box>
  );
};

export default SignupCompany;
