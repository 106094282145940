import { useState } from "react";
import { Box, Button, Typography } from "@mui/material";

import "../../styles/components/bounty/bountyinterestrequest.css";
import { ReactComponent as InterestIcon } from "../../assets/icons/interest-icon.svg";
import { ReactComponent as ReportIcon } from "../../assets/icons/repport-icon.svg";
import ReportRequestStepper from "./ReportRequestStepper";

export default function ReportRequestStep() {
  const [step, setStep] = useState(1);
  return (
    <Box className="send-request">
      {step === 1 && (
        <Box className="bounty-details-footer ">
          <Box className="footer-text">
            <Typography>Ready to Dive In?</Typography>
            <Typography>
              If this project catches your eye, hit ‘Show Interest’ to get
              started and let the hacking begin! 👾🔍
            </Typography>
          </Box>
          <Button
            variant="outlined"
            className={`interest-btn`}
            onClick={() => setStep(2)}
          >
            Send Interest
            <InterestIcon alt="interest-icon" />
          </Button>
        </Box>
      )}
      {step === 2 && (
        <Box className="bounty-details-footer ">
          <Box className="footer-text">
            <Typography>Access Granted!</Typography>
            <Typography>
              Your request is approved—time to hack away and send in your
              report! 🕵️‍♂️📄
            </Typography>
          </Box>
          <Button
            variant="outlined"
            className={`interest-btn`}
            onClick={() => setStep(3)}
          >
            Create Report
            <ReportIcon alt="interest-icon" />
          </Button>
        </Box>
      )}
      {step === 3 && <ReportRequestStepper />}
    </Box>
  );
}
