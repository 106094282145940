import { Box, Button, IconButton, InputBase, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

import HackerLayout from "../../componants/layout/HackerLayout";
import BountyLisItem from "../../componants/bounties/BountyLisItem";
import "../../styles/bountiespage.css";

import { ReactComponent as BountyIcon } from "../../assets/icons/bounty-icon.svg";
import { ReactComponent as FavoriteIcon } from "../../assets/icons/favorite_icon.svg";
import { ReactComponent as TreeIcon } from "../../assets/icons/tree-icon.svg";
import bounty_icon from "../../assets/icons/bounty-icon.svg";
import filter_icon from "../../assets/icons/filter-icon.svg";
import slack_icon from "../../assets/icons/slack-icon.svg";

const BountiesPage = () => {
  return (
    <HackerLayout>
      {({ filterOpen, setFilterOpen }) => (
        <Box px={{ xs: 1, md: 2, lg: 3 }}>
          <Box display="flex" mb={5} sx={{}}>
            <img src={bounty_icon} alt="bounty-icon" />
            <Typography
              variant="h3"
              ml={1}
              sx={{ fontWeight: 600, fontSize: "16px" }}
            >
              Bounties
            </Typography>
          </Box>
          <Box className="bounties-header" mb={2}>
            <Box display="flex" className="header-btns">
              <Button
                variant="outlined"
                className={`header-btn ${"active-btn"}`}
              >
                <BountyIcon alt="time-icon" />
                Active Projects({2})
              </Button>
              <Button variant="outlined" className={`header-btn ${""}`}>
                <FavoriteIcon alt="favorite-icon" />
                Favorites({0})
              </Button>
              <Button variant="outlined" className={`header-btn ${""}`}>
                <TreeIcon alt="tree-icon" />
                Invitations({0})
              </Button>
            </Box>
            <Box className="notifs-search-container" sx={{}}>
              <SearchIcon color="#717575" />
              <InputBase
                className="notifs-search-input"
                placeholder="Search by Project or Username"
                inputProps={{ "aria-label": "search" }}
                sx={{ flexGrow: 1 }}
              />
              <IconButton
                onClick={() => {
                  setFilterOpen(!filterOpen);
                }}
              >
                <img src={filter_icon} alt="filter-icon" />
              </IconButton>
            </Box>
          </Box>
          <Box className="bounties-list">
            <BountyLisItem
              icon={slack_icon}
              title=" Slack Bounty Hack"
              description="Lorem ipsum dolor sit amet consectetur. Sit ultricies nulla aliquet amet
        sagittis mi tristique facilisi..."
              minPrice="$15"
              maxPrice="$75k"
              tags={["Domain", "Wildcard"]}
              users={20}
              fork={129}
            />
            <BountyLisItem
              icon={slack_icon}
              title=" Slack Bounty Hack"
              description="Lorem ipsum dolor sit amet consectetur. Sit ultricies nulla aliquet amet
        sagittis mi tristique facilisi..."
              minPrice="$15"
              maxPrice="$75k"
              tags={["Domain", "Wildcard"]}
              users={20}
              fork={129}
            />
            <BountyLisItem
              icon={slack_icon}
              title=" Slack Bounty Hack"
              description="Lorem ipsum dolor sit amet consectetur. Sit ultricies nulla aliquet amet
        sagittis mi tristique facilisi..."
              minPrice="$15"
              maxPrice="$75k"
              tags={["Domain", "Wildcard"]}
              users={20}
              fork={129}
            />
            <BountyLisItem
              icon={slack_icon}
              title=" Slack Bounty Hack"
              description="Lorem ipsum dolor sit amet consectetur. Sit ultricies nulla aliquet amet
        sagittis mi tristique facilisi..."
              minPrice="$15"
              maxPrice="$75k"
              tags={["Domain", "Wildcard"]}
              users={20}
              fork={129}
            />
          </Box>
        </Box>
      )}
    </HackerLayout>
  );
};

export default BountiesPage;
