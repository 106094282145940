import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  InputBase,
  IconButton,
  Button,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import TuneIcon from "@mui/icons-material/Tune";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import styled from "styled-components";

import { ReactComponent as FlagIcon } from "../../assets/icons/flag_icon.svg";

const ApprovedFlagIcon = styled(FlagIcon)`
  path {
    stroke: #12B76A !important;
  }
`;
const ReviewFlagIcon = styled(FlagIcon)`
  path {
    stroke: #F79009 !important;
  }
`;
const ClosedFlagIcon = styled(FlagIcon)`
  path {
    stroke: #F04438 !important;
  }
`;

const mockData = [
  {
    id: "My report 1",
    name: "Teklab",
    date: "December 16, 2024",
    type: "CVSS",
    status: "Approved",
  },
  {
    id: "My report 1",
    name: "Teklab",
    date: "December 16, 2024",
    type: "CVSS",
    status: "Under Review",
  },
  {
    id: "My report 1",
    name: "Teklab",
    date: "December 16, 2024",
    type: "CVSS",
    status: "Closed",
  },
];

const getStatusIcon = (status) => {
  switch (status) {
    case "Approved":
      return <ApprovedFlagIcon />;
    case "Under Review":
      return <ReviewFlagIcon />;
    case "Closed":
      return <ClosedFlagIcon />;
    default:
      return null;
  }
};

export default function TransactionTable() {
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <Box className="transaction-table" sx={{ mt: 3 }}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={3}
      >
        <Typography variant="h6" color="text.secondary">
          Paynents Transactions
        </Typography>
        <Button className="export-btn">
          <FileDownloadOutlinedIcon /> Export
        </Button>
      </Box>

      <Box mb={3}>
        {/* <Paper className="search-paper">
          <IconButton>
            <SearchIcon />
          </IconButton>
          <InputBase
            className="search-input"
            placeholder="Search Report"
            inputProps={{ "aria-label": "search report" }}
            fullWidth
          />
          <IconButton>
            <TuneIcon />
          </IconButton>
        </Paper> */}
        <Box
          className="notifs-search-container"
        >
          <IconButton>
            <SearchIcon />
          </IconButton>
          <InputBase
            className="notifs-search-input"
            placeholder="Search by Project or Username"
            inputProps={{ "aria-label": "search" }}
            sx={{ flexGrow: 1 }}
          />
          <IconButton
            onClick={() => {
              setFilterOpen(!filterOpen);
            }}
          >
            <TuneIcon alt="filter-icon" />
          </IconButton>
        </Box>
      </Box>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Transaction ID</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mockData.map((row, index) => (
              <TableRow key={index}>
                <TableCell>{row.id}</TableCell>
                <TableCell>{row.date}</TableCell>
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell className="status-cell">
                  {getStatusIcon(row.status)}
                  {row.status}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
