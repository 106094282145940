import React from "react";
import { Box, Typography, Chip } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

import "../../styles/components/bounty/chatnotif.css";
import ChatNotifIcon from "../../assets/icons/chat-change-notif-icon.svg";

const ChatNotifItem = ({ author, fromSeverity, toSeverity, date }) => {
  const getSeverityColor = (severity) => {
    switch (severity.toLowerCase()) {
      case "critical":
        return "#FF4D4D";
      case "medium":
        return "#FFA500";
      default:
        return "#808080";
    }
  };

  return (
    <Box className="chat-notif-item">
      <Box className="user-icon">
        <img src={ChatNotifIcon} alt="notif-icon" />
      </Box>
      <Box className="notif-content">
        <Box className="user-info">
          <Typography variant="body1" component="span" className="author-name">
            {author.name}
          </Typography>
          <Chip
            label={author.role}
            size="small"
            className="role-chip"
            sx={{
              backgroundColor: "#f0f0f0",
              fontSize: "0.75rem",
              height: "20px",
            }}
          />
        </Box>
        <Box className="severity-change">
          <Typography variant="body2" component="span">
            Severity change:
          </Typography>
          <Chip
            label={fromSeverity}
            size="small"
            sx={{
              backgroundColor: getSeverityColor(fromSeverity),
              color: "white",
              fontSize: "0.75rem",
              height: "20px",
              margin: "0 8px",
            }}
          />
          <ArrowForwardIcon sx={{ color: "#666", fontSize: "1rem" }} />
          <Chip
            label={toSeverity}
            size="small"
            sx={{
              backgroundColor: getSeverityColor(toSeverity),
              color: "white",
              fontSize: "0.75rem",
              height: "20px",
              margin: "0 8px",
            }}
          />
        </Box>
        <span className="notif-date">
          {date}
        </span>
      </Box>
    </Box>
  );
};

export default ChatNotifItem;
