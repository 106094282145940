import React from "react";
import {
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";

import "../../styles/navbar/navbar.css";
import logo from "../../assets/images/Logo-dark.png";
import discovery from "../../assets/icons/discovery-icon.svg";
import dashboard from "../../assets/icons/dashboard-icon.svg";
import bounties from "../../assets/icons/bounty-icon.svg";
import vault from "../../assets/icons/vault-icon.svg";
import legends from "../../assets/icons/legends-icon.svg";
import settings from "../../assets/icons/settings-icon.svg";
import x_icon from "../../assets/icons/icon-brand-x.svg";
import instagram from "../../assets/icons/icon-brand-instagram.svg";
import discord from "../../assets/icons/icon-brand-discord.svg";
import youtube from "../../assets/icons/icon-brand-youtube.svg";

const HackerNavbar = () => {
  const routes = [
    {
      title: "Discovery",
      path: "/discovery",
      icon: <img src={discovery} alt="discovery" />,
    },
    {
      title: "HQ",
      path: "/dashboard",
      icon: <img src={dashboard} alt="dashboard" />,
    },
    {
      title: "Bounties",
      path: "/bounties",
      icon: <img src={bounties} alt="bounties" />,
    },
    {
      title: "Vault",
      path: "/vault",
      icon: <img src={vault} alt="vault" />,
    },
    {
      title: "Legends",
      path: "/leaderboard",
      icon: <img src={legends} alt="legends" />,
    },
  ];
  const social_medias_links = [
    {
      link: "#",
      icon: <img src={x_icon} alt="x_icon" />,
    },
    {
      link: "#",
      icon: <img src={instagram} alt="instagram" />,
    },
    {
      link: "#",
      icon: <img src={discord} alt="discord" />,
    },
    {
      link: "#",
      icon: <img src={youtube} alt="youtube" />,
    },
  ];

  return (
    <Box className="drawer">
      <Box className="logo">
        <Link to={"/"}>
          <img src={logo} alt="logo" />
        </Link>
      </Box>
      <List className="nav">
        {routes.map((element, index) => (
          <ListItem key={index} className="nav-item" disablePadding>
            <Link to={element.path}>
              <ListItemButton className="nav-item-btn">
                <ListItemIcon className="nav-item-icon">
                  {element.icon}
                </ListItemIcon>
                <ListItemText
                  className="nav-item-text"
                  primary={element.title}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
        <Divider sx={{ my: 1 }} orientation={{sm: "vertical", md: "horizontal"}} className="nav-divider" />
        <ListItem className="nav-item" disablePadding>
          <Link to={"/settings"}>
            <ListItemButton className="nav-item-btn">
              <ListItemIcon className="nav-item-icon">
                <img src={settings} alt="settings" />
              </ListItemIcon>
              <ListItemText className="nav-item-text" primary="Settings" />
            </ListItemButton>
          </Link>
        </ListItem>
      </List>
      <Box className="nav-bottom">
        <List className="nav">
          {social_medias_links.map((element, index) => (
            <Link key={index} to={element.path}>
              <ListItemIcon className="nav-item-icon">
                {element.icon}
              </ListItemIcon>
            </Link>
          ))}
        </List>
        <Box className="nav-copyright">
          <Typography variant="body1" className="nav-copyright">
            2024
          </Typography>
          <Typography variant="body1" className="nav-copyright">
            ©
          </Typography>
          <Typography variant="body1" className="nav-copyright">
            TekLAB
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default HackerNavbar;
