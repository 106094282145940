import React from 'react';

import ReportListItem from './ReportListItem';
import '../../styles/components/bounty/reportlist.css';



const ReportsList = () => {
  // Exemple de données de rapports
  const reports = [
    {
      id: 1,
      title: 'My Report',
      submissionDate: '2024/04/22',
    },
    {
      id: 2,
      title: 'Security Analysis Report',
      submissionDate: '2024/04/21',
    },
    {
      id: 3,
      title: 'Vulnerability Assessment',
      submissionDate: '2024/04/20',
    },
  ];

  return (
    <div className="reports-list">
      {reports.map((report) => (
        <ReportListItem
          key={report.id}
          title={report.title}
          submissionDate={report.submissionDate}
        />
      ))}
    </div>
  );
};

export default ReportsList;