import { Box, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";

import slack_icon from "../../assets/icons/slack-icon.svg";
import salesforce_icon from "../../assets/icons/salesforce-icon.svg";
import increase from "../../assets/icons/increase-arrow.svg";
import decrease from "../../assets/icons/decrease-arrow.svg";

export default function BountyRankingBox() {
  return (
    <Box className="analytic-box">
      <Box className="ranking-header">
        <Typography variant="bosy2" className="title">
          Project Ranking
        </Typography>
        <Typography variant="bosy1" className="description">
          Behold the leaderboard of legends—where only the most critical finds
          make the cut!
        </Typography>
      </Box>

      <Grid className="discoveries-items" container spacing={2} mt={2}>
        {[1, 2, 3, 4, 5, 6].map((elm, index) => (
          <Grid key={index} size={{ xs: 6, md: 4, lg: 2 }}>
            <Box display="flex" justifyContent="start" alignItems="center">
              <Box className="icon-container tecno">
                <img
                  src={index % 2 === 0 ? salesforce_icon : slack_icon}
                  alt="icon"
                />
              </Box>
              <Box sx={{ ml: 2 }}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    color: "#333333",
                  }}
                >
                  Microsoft
                </Typography>
                <Box className="status">
                  <img
                    src={index % 2 === 0 ? increase : decrease}
                    alt="status icon"
                    width={18}
                    height={10}
                  />
                  <Typography variant="body2" className=" ">
                    27
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
