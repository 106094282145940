import React from "react";
import { Box } from "@mui/material";

import "../../styles/auth/signup.css";
import HackerImage from "../../assets/images/cybersecurity-specialist-operates-high-tech-military-control-center-login.png";
import LoginForm from "../../componants/loginForm";

const Login = () => {
  return (
    <Box className="signup-container">
      <LoginForm />
      <Box
        className="signup-img"
        sx={{ backgroundImage: `url(${HackerImage})` }}
      />
    </Box>
  );
};

export default Login;
