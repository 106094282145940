import React, { useState } from "react";
import { Avatar, Button, Box, Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import Comment from "./Comment";
import "../../styles/components/bounty/comments.css";
import ChatNotifItem from "./ChatNotifItem";

dayjs.extend(relativeTime);

const Comments = ({ comments = [] }) => {
  const [newComment, setNewComment] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    setNewComment("");
    console.log(newComment);
  };

  return (
    <Box className="comments-container">
      <Box className="comments-list">
        {comments.map((comment, index) => (
          <Comment key={index} comment={comment} />
        ))}
      </Box>

      <ChatNotifItem
        author={{
          name: "David Adegoke",
          role: "Admin",
        }}
        fromSeverity="Critical"
        toSeverity="Medium"
        date="Dec 16, 2024"
      />
      <ChatNotifItem
        author={{
          name: "David Adegoke",
          role: "Admin",
        }}
        fromSeverity="Critical"
        toSeverity="Medium"
        date="Dec 16, 2024"
      />

      <form onSubmit={handleSubmit} className="comment-form">
        <Box className="comment-header ">
          <Avatar
            src={
              "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=580&q=80"
            }
            alt={"Avatar"}
            className="comment-avatar"
          />
          <Box className="text-area-container">
            <Box className="area-actions">
              <Button className={`${"active"}`}>write</Button>
              <Button>preview</Button>
            </Box>
            <textarea
              id="text-area-container"
              placeholder="In scope infor"
              className="area-input"
              multiline
              rows={4}
            />
          </Box>
        </Box>
        <div className="comment-form-actions">
          <Typography variant="body2">
            Styling with Markdown supported
          </Typography>
          <span>
            <Button
              startIcon={<AttachFileIcon />}
              variant="text"
              component="label"
              className="action-btn"
            >
              Attach file or image
              <input type="file" hidden />
            </Button>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className="action-btn submit"
            >
              Post Comment
            </Button>
          </span>
        </div>
      </form>
    </Box>
  );
};

export default Comments;
