import React, { useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  InputBase,
  Typography,
} from "@mui/material";

import "../../styles/components/notification/notificationBox.css";
import notif_icon from "../../assets/icons/notification-icon.svg";
import filter_icon from "../../assets/icons/filter-icon.svg";
import SearchIcon from "@mui/icons-material/Search";
import NotificationItem from "./NotificationItem";
import tree_icon from "../../assets/icons/tree-icon.svg";
import FilterComponent from "../filter/FilterComponent";

export default function NotificationBox() {
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <Box className="backdrop">
      <Box className="notifications">
        <Box className="notifs-header">
          <img src={notif_icon} alt="notif-icon" />
          <Typography variant="h3" className="title">
            Notification
          </Typography>
          <Button className="mark-read-btn">Mark all as Read</Button>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box className="notifs-search-container">
          <SearchIcon color="#717575" />
          <InputBase
            className="notifs-search-input"
            placeholder="Search by Project or Username"
            inputProps={{ "aria-label": "search" }}
            sx={{ flexGrow: 1 }}
          />
          <IconButton
            onClick={() => {
              setFilterOpen(!filterOpen);
            }}
          >
            <img src={filter_icon} alt="filter-icon" />
          </IconButton>
        </Box>
        {filterOpen && <FilterComponent isDate={true} />}
        <Box>
          <NotificationItem
            status={0}
            icon={tree_icon}
            tag="You've Received an Invite!"
            title="Teklab sent you an Invite"
            actionText="Explore"
          />
          <NotificationItem
            status={0}
            icon={tree_icon}
            tag="You've Received an Invite!"
            title="TekLab approved your request. Dive in and start uncovering vulnerabilities!"
            actionText="Go to message"
          />
          <NotificationItem
            status={0}
            icon={tree_icon}
            tag="You've Received an Invite!"
            title="Teklab sent you an Invite"
            actionText="Explore"
          />
          <NotificationItem
            status={0}
            icon={tree_icon}
            tag="You've Received an Invite!"
            title=" TekLab approved your request. Dive in and start uncovering vulnerabilities! TekLab approved your request. Dive in and start uncovering vulnerabilities!"
            actionText="Explore"
          />
        </Box>
      </Box>
    </Box>
  );
}
