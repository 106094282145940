import { Box, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import HackerLayout from "../../componants/layout/HackerLayout";
import BountyInfoTabs from "../../componants/bounties/BountyInfoTab";
import BountyGlobalInfo from "../../componants/bounties/BountyGlobalInfo";

import "../../styles/bountydetailspage.css";
import { ReactComponent as BountyIcon } from "../../assets/icons/bounty-icon.svg";
import { ReactComponent as DoubleArrowIcon } from "../../assets/icons/double-arrow-icon.svg";

const BountyDetailPage = () => {
  const { title } = useParams();
  return (
    <HackerLayout>
      {({ filterOpen, setFilterOpen }) => (
        <>
          <Box px={{ xs: 1, md: 2, lg: 3 }} className="bounty-details">
            <Box display="flex" mb={2}>
              <BountyIcon alt="bounty-icon" />
              <Typography
                variant="h3"
                ml={1}
                sx={{ fontWeight: 600, fontSize: "16px" }}
              >
                Bounties
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mb={4} ml={2}>
              <Typography
                variant="h3"
                mx={1}
                sx={{ fontWeight: 600, fontSize: "16px", color: "#717575" }}
              >
                Bounties
              </Typography>
              <DoubleArrowIcon alt="double-arrow-icon" />
              <Typography
                variant="h3"
                ml={1}
                sx={{ fontWeight: 600, fontSize: "16px" }}
              >
                {title}
              </Typography>
            </Box>
            <BountyGlobalInfo title={title} />
            <BountyInfoTabs />
          </Box>
         
        </>
      )}
    </HackerLayout>
  );
};

export default BountyDetailPage;
