import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  IconButton,
  Divider,
  OutlinedInput,
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { Visibility, VisibilityOff } from "@mui/icons-material";

import "../styles/auth/signup.css";
import logo from "../assets/images/Logo.png";
import { MuiTelInput } from "mui-tel-input";
import { Link } from "react-router-dom";

const SignupForm = ({ page, subtitle }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [value, setValue] = React.useState("");

  const handlePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (newValue) => {
    setValue(newValue);
  };

  return (
    <Box className="signup-content">
      <Box className="signup-box">
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }} className="signup-header">
            <img src={logo} alt="logo" className="logo" />
            <Box display="flex" alignItems="center">
              <Typography variant="body2" className="signup-title">
                CREATE ACCOUNT
              </Typography>
              <Divider sx={{ flexGrow: 1, maxWidth: "250px", ml: 1 }} />
            </Box>
            <Typography variant="h4" className="signup-heading">
              Configure Access
            </Typography>
            <Typography variant="body1" className="signup-subtitle">
              {subtitle}
            </Typography>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}></Grid>
          {page === "company_signup" && (
            <>
              <Grid size={{ xs: 12, md: 6 }}>
                <label>Company Name</label>
                <OutlinedInput
                  fullWidth
                  placeholder="Company Name"
                  className="signup-input"
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                />
              </Grid>
              <Grid size={{ xs: 12, md: 6 }}>
                <label>Phone number</label>
                <MuiTelInput
                  fullWidth
                  slotProps={{
                    inputLabel: {
                      shrink: true,
                    },
                  }}
                  className="signup-input"
                  value={value}
                  onChange={handleChange}
                  sx={{ mt: 1.5 }}
                />
              </Grid>
            </>
          )}
          <Grid size={{ xs: 12, md: 6 }}>
            <label>First Name</label>
            <OutlinedInput
              fullWidth
              placeholder="First name"
              className="signup-input"
              slotProps={{
                inputLabel: {
                  shrink: true,
                },
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <label>Last Name</label>
            <OutlinedInput
              fullWidth
              placeholder="Last name"
              className="signup-input"
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <label>Email Address</label>
            <OutlinedInput
              fullWidth
              placeholder="Email address"
              type="email"
              className="signup-input"
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <label>Password</label>
            <OutlinedInput
              fullWidth
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              className="signup-input"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton onClick={handlePasswordVisibility}>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                  className="signup-checkbox"
                />
              }
              label={
                <Typography variant="body2" className="signup-terms">
                  By signing up, you hereby agree to our{" "}
                  <span className="signup-link">Terms & Conditions</span> and{" "}
                  <span className="signup-link">Code of Conduct</span>
                </Typography>
              }
            />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              disabled={!checked}
              className="signup-button"
            >
              Hack Your Way In!
            </Button>
            <Divider className="signup-divider">
              <Typography variant="body2" color="textSecondary">
                Or
              </Typography>
            </Divider>
            <Link to={"/login"}>
              <Button
                fullWidth
                variant="outlined"
                color="secondary"
                className="signup-secondary-button"
              >
                Sign in
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SignupForm;
