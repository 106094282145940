import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import "../../styles/rankingComponent.css";
import increase from "../../assets/icons/increase-arrow.svg";
import decrase from "../../assets/icons/decrease-arrow.svg";
import user_default from "../../assets/images/cyber-criminals-team-hacking-computer-system-coding-malicious-software.png";

export default function HighestRanking() {
  return (
    <Card className="rankings">
      <Box className="rankings-header">
        <Typography variant="h3" mb={2}>Highest Critical Reputation</Typography>
        <Typography variant="boby1" >
          Behold the leaderboard of legends—where only the most critical finds
          make the cut!
        </Typography>
      </Box>
      <Box className="ranking-body">
        <TableContainer>
          <Table
            sx={{ textAlign: "center" }}
            aria-label="simple table"
            className="overview-table-container rankings-table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Hacker Info</Typography>
                </TableCell>
                <TableCell>
                  <Box className="boxed-head">
                    <Typography align="center">Reputation</Typography>
                  </Box>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[1, 2, 3, 4, 5].map((elm, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Box className="table-row-item">
                      <img
                        src={index % 2 === 0 ? increase : decrase}
                        alt="status"
                      />
                      <Typography variant="body1" mx={1}>
                        {elm}
                      </Typography>
                      <Avatar
                        sx={{ bgcolor: "#717575" }}
                        variant="rounded"
                        src={user_default}
                        alt="hacker-avatar"
                      />
                      <Typography align="right" ml={1}>
                        consult.xxxxx
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell align="center">3000</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
}
