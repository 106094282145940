import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import "../../styles/components/bounty/analyticbox.css";
import { ReactComponent as AnalyticIcon } from "../../assets/icons/analytic-icon.svg";
import { ReactComponent as TimeIcon } from "../../assets/icons/time-icon.svg";
import { ReactComponent as GiftIcon } from "../../assets/icons/gift-icon.svg";
import { ReactComponent as FileAssetIcon } from "../../assets/icons/file-asset.svg";

export default function BountyAnalyticBox() {
  return (
    <>
      <Box className="analytic-box">
        <Box className="title">
          <AnalyticIcon alt="analytic-icon" />
          <Typography variant="bosy1">Analytics</Typography>
        </Box>
        <Grid container spacing={2} width="100%">
          <Grid
            item
            size={{ xs: 12, md: 6 }}
            className="time-box"
            sx={{ bgcolor: "#F8FDFD" }}
          >
            <TimeIcon alt="time-icon" />
            <Typography variant="bosy1" className="box-value">
              24days, 22hours
            </Typography>
            <Typography variant="bosy2" className="box-description">
              Average Time to First Response
            </Typography>
          </Grid>
          <Grid
            item
            size={{ xs: 12, md: 6 }}
            className="time-box"
            sx={{ bgcolor: "#FDFBF8" }}
          >
            <TimeIcon alt="time-icon" />
            <Typography variant="bosy1" className="box-value">
              24days, 22hours
            </Typography>
            <Typography variant="bosy2" className="box-description">
              Average Time to Traige
            </Typography>
          </Grid>
          <Grid
            item
            size={{ xs: 12, md: 6 }}
            className="time-box"
            sx={{ bgcolor: "#F8F9FD" }}
          >
            <TimeIcon alt="time-icon" />
            <Typography variant="bosy1" className="box-value">
              24days, 22hours
            </Typography>
            <Typography variant="bosy2" className="box-description">
              Average Time to First Response
            </Typography>
          </Grid>
          <Grid
            item
            size={{ xs: 12, md: 6 }}
            className="time-box"
            sx={{ bgcolor: "#FDF8FC" }}
          >
            <TimeIcon alt="time-icon" />
            <Typography variant="bosy1" className="box-value">
              24days, 22hours
            </Typography>
            <Typography variant="bosy2" className="box-description">
              Average Time to First Response
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box className="analytic-box bug-box">
        <Box className="title">
          <AnalyticIcon alt="analytic-icon" />
          <Typography variant="bosy1">Bug Credential Document</Typography>
        </Box>
        <Typography variant="bosy1">
          Lorem ipsum dolor sit amet consectetur. Sit ultricies nulla aliquet
          amet sagittis mi tristique facilisi...
        </Typography>
      </Box>
      <Box className="analytic-box bug-box">
        <Box className="title">
          <AnalyticIcon alt="analytic-icon" />
          <Typography variant="bosy1">Out Of Scope</Typography>
        </Box>
        <Typography variant="bosy1">
          Lorem ipsum dolor sit amet consectetur. Sit ultricies nulla aliquet
          amet sagittis mi tristique facilisi...
        </Typography>
      </Box>
      <Grid container spacing={2} width="100%">
        <Grid item size={{ xs: 12, md: 6 }} className="analytic-box">
          <Box className="title">
            <GiftIcon alt="analytic-icon" />
            <Typography variant="bosy1">Reward Configurations</Typography>
          </Box>
          <TableContainer className="analytic-table">
            <Table
              sx={{ width: "100% !important", textAlign: "center" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Grid
                      container
                      spacing={2}
                      className="boxed-head"
                      sx={{ minWidth: "250px" }}
                    >
                      <Grid size={6}>
                        <Typography align="center">Severity</Typography>
                      </Grid>
                      <Grid size={6}>
                        <Typography align="center">Signal</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[1, 2, 3, 4, 5].map((elm, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Grid container spacing={2} className="">
                        <Grid size={6}>
                          <Box align="center" className="badge">
                            Medium
                          </Box>
                        </Grid>
                        <Grid size={6}>
                          <Typography align="center">700K</Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid item size={{ xs: 12, md: 6 }} className="analytic-box">
          <Box className="title">
            <FileAssetIcon alt="file-icon" />
            <Typography variant="bosy1">Assets</Typography>
          </Box>
          <TableContainer className="analytic-table">
            <Table
              sx={{ width: "100% !important", textAlign: "center" }}
              aria-label="simple table"
            >
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Grid
                      container
                      spacing={2}
                      className="boxed-head"
                      sx={{ minWidth: "250px" }}
                    >
                      <Grid size={4}>
                        <Typography align="center">Asset</Typography>
                      </Grid>
                      <Grid size={4}>
                        <Typography align="center">Link</Typography>
                      </Grid>
                      <Grid size={4}>
                        <Typography align="center">Asset value</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {[1, 2, 3].map((elm, index) => (
                  <TableRow
                    key={index}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell>
                      <Grid container spacing={2} className="">
                        <Grid size={4}>
                          <Typography align="center">Linkedin</Typography>
                        </Grid>
                        <Grid size={4}>
                          <a href="https://teklabspace.com/" align="center">
                            https://teklabspace.com/
                          </a>
                        </Grid>
                        <Grid size={4}>
                          <Box align="center" className="badge">
                            High
                          </Box>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}
