import React, { useState } from "react";
import { Avatar, Box, Chip, Typography, IconButton, Menu, MenuItem } from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "../../styles/components/bounty/comments.css";

dayjs.extend(relativeTime);

const TodoItem = ({ text }) => {
  const parts = text.split("#");
  const hasReference = parts.length > 1;
  const reference = hasReference ? "#" + parts[1].trim() : "";
  const content = hasReference ? parts[0].trim() : text;
  const hasQuestion = content.includes("❓");

  return (
    <div className="todo-item">
      <span className="todo-bullet">-</span>
      <span className="todo-checkbox">[]</span>
      <span className="todo-content">
        {content.replace("❓", "")}
        {hasQuestion && <span className="question-mark">❓</span>}
        {hasReference && <span className="reference">{reference}</span>}
      </span>
    </div>
  );
};

const Comment = ({ comment }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderContent = () => {
    return comment.content.split("\\n").map((line, index) => {
      // Titre avec ##
      if (line.trim().startsWith("##")) {
        return (
          <Typography key={index} className="section-title">
            {line.trim()}
          </Typography>
        );
      }
      // Sous-titre avec ###
      if (line.trim().startsWith("###")) {
        return (
          <Typography key={index} className="section-subtitle">
            {line.trim()}
          </Typography>
        );
      }
      // Ligne todo commençant par -
      if (line.trim().startsWith("-")) {
        return <TodoItem key={index} text={line.trim().substring(1).trim()} />;
      }
      // Séparateur
      if (line.trim() === "---") {
        return <div key={index} className="separator" />;
      }
      // Ligne vide
      if (line.trim() === "") {
        return <div key={index} className="empty-line" />;
      }
      // Texte normal
      return (
        <Typography key={index} className="normal-text">
          {line.trim()}
        </Typography>
      );
    });
  };

  return (
    <Box className="comment">
      <Box className="comment-header">
        <Avatar
          src={comment.author.avatar}
          alt={comment.author.name}
          className="comment-avatar"
        />
        <Box className="comment-header-name">
          <Box className="comment-info">
            <p>
              <span className="author-name">{comment.author.name}</span>
              <span className="author-role">{comment.author.role}</span>
            </p>
            <Chip label={comment.author.role || "Hacker"} className="role-chip" />
          </Box>
          <span className="comment-date">Dec 16, 2024</span>
        <IconButton
          aria-label="more"
          aria-controls={open ? 'comment-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
        >
          <MoreHorizIcon />
        </IconButton>
        </Box>
        <Menu
          id="comment-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={handleClose}>Modifier</MenuItem>
          <MenuItem onClick={handleClose}>Supprimer</MenuItem>
          <MenuItem onClick={handleClose}>Signaler</MenuItem>
        </Menu>
      </Box>
      <Box className="comment-body">{renderContent()}</Box>
    </Box>
  );
};

export default Comment;
