import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Grid from "@mui/material/Grid2";

import "../../styles/rankingComponent.css";
import increase from "../../assets/icons/increase-arrow.svg";
import decrase from "../../assets/icons/decrease-arrow.svg";
import user_default from "../../assets/images/cyber-criminals-team-hacking-computer-system-coding-malicious-software.png";

export default function RankingComponant() {
  return (
    <Card className="rankings">
      <Box className="rankings-header">
        <Typography variant="h3" mb={2}>
          Legends Global Ranking: Hackers Assemble
        </Typography>
        <Typography variant="boby1">
          Welcome to the ultimate showdown! Climb the ranks, prove your skills,
          and see where you stand among the world's best hackers!
        </Typography>
      </Box>
      <Box className="ranking-body">
        <TableContainer className="overview-table-container rankings-table">
          <Table
            sx={{ width: "100% !important", textAlign: "center" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography>Hacker Info</Typography>
                </TableCell>
                <TableCell>
                  <Grid
                    container
                    spacing={2}
                    className="boxed-head"
                    sx={{ minWidth: "250px" }}
                  >
                    <Grid size={4}>
                      <Typography align="center">Reputation</Typography>
                    </Grid>
                    <Grid size={4}>
                      <Typography align="center">Signal</Typography>
                    </Grid>
                    <Grid size={4}>
                      <Typography align="center">Impact</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[1, 2, 3, 4, 5, 6, 7].map((elm, index) => (
                <TableRow
                  key={index}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>
                    <Box className="table-row-item">
                      <img
                        src={index % 2 === 0 ? increase : decrase}
                        alt="status"
                      />
                      <Typography variant="body1" mx={1}>
                        {elm}
                      </Typography>
                      <Avatar
                        sx={{ bgcolor: "#717575" }}
                        variant="rounded"
                        src={user_default}
                        alt="hacker-avatar"
                      />
                      <Typography align="right" ml={1}>
                        consult.xxxxx
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Grid container spacing={2} className="">
                      <Grid size={4}>
                        <Typography align="center">3000</Typography>
                      </Grid>
                      <Grid size={4}>
                        <Typography align="center">700</Typography>
                      </Grid>
                      <Grid size={4}>
                        <Typography align="center">15.1</Typography>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Card>
  );
}
