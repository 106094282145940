import { Box, Button, Chip, Typography } from "@mui/material";

export default function NotificationItem({
  status,
  icon,
  title,
  tag,
  actionText,
}) {
  return (
    <Box className="notif-item">
      <Box display="flex" alignItems="center">
        <Box
          sx={{
            width: "8px",
            height: "8px",
            background: status === 0 ? "#A633C0" : "#E9ECEC",
            borderRadius: "50%",
            mr: 2,
          }}
        />
        <Box mr={2} className="item-icon">
          <img src={icon} alt="icon" />
        </Box>
        <Chip label={tag} className="item-tag" />
      </Box>
      <Typography variant="body1" className="item-title">
        {title}
      </Typography>
      <Button variant="body1" className="item-action-btn">
        {actionText}
      </Button>
    </Box>
  );
}
