import { Box, Card, Typography } from "@mui/material";

import "../../styles/components/discoverycard.css";

import increase from "../../assets/icons/increase-arrow.svg";
import decrease from "../../assets/icons/decrease-arrow.svg";

export default function BountyCard({ icon, title, id, progress }) {
  return (
    <Card className="discovery">
      <Box display="flex" justifyContent="start" alignItems="center">
        <Box className="icon-container tecno">
          <img src={icon} alt="icon" />
        </Box>
        <Typography
          sx={{
            fontWeight: 600,
            color: "#333333",
            ml: 1,
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box className="mini-box " my={1} sx={{width: "100%"}}>
          <Typography variant="body2">{id}</Typography>
          {progress === "increase" ? (
            <img src={increase} alt="user group icon" width={18} height={10} className="progress-icon" />
          ) : (
            <img src={decrease} alt="user group icon" width={18} height={10} className="progress-icon" />
          )}
        </Box>
      </Box>
    </Card>
  );
}