import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { Button, Typography } from "@mui/material";

import { ReactComponent as InterestIcon } from "../../assets/icons/interest-icon.svg";
import { ReactComponent as ProgramIcon } from "../../assets/icons/program-icon.svg";
import { ReactComponent as RepportIcon } from "../../assets/icons/repport-icon.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/comment-icon.svg";
import BountyAnalyticBox from "./BountyAnalyticBox";
import BountyRankingBox from "./BountyRankingBox";
import ReportRequestStep from "./ReportRequestComponent";
import Comments from "./Comments";
import ReportsList from "./ReportsList";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BountyInfoTabs() {
  const [value, setValue] = React.useState(0);

  const comments = [
    {
      author: {
        name: 'David Adapala',
        avatar: '/path/to/avatar.jpg'
      },
      role: 'Admin',
      content: '## @todo\n- [] ui-designs from #28\n- [] @output ❓ #30\n- next #16',
      timestamp: '2024-12-08T20:28:31+01:00',
      statusChange: { from: 'Critical', to: 'Medium' }
    },
    {
      author: {
        name: 'David Adapala',
        avatar: '/path/to/avatar.jpg'
      },
      role: 'Admin',
      content: '## @todo\n- [] ui-designs from #28\n- [] @output ❓ #30\n- next #16',
      timestamp: '2024-12-08T20:28:31+01:00',
      statusChange: { from: 'Critical', to: 'Medium' }
    },
    {
      author: {
        name: 'David Adapala',
        avatar: '/path/to/avatar.jpg'
      },
      role: 'Admin',
      content: '## @todo\n- [] ui-designs from #28\n- [] @output ❓ #30\n- next #16',
      timestamp: '2024-12-08T20:28:31+01:00',
      statusChange: { from: 'Critical', to: 'Medium' }
    },
  ];

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function labelProp(index) {
    switch (index) {
      case 0:
        return (
          <Typography
            className={`tab-label ${index === value ? "active" : ""}`}
          >
            <ProgramIcon alt="program-icon" /> Program Details
          </Typography>
        );
      case 1:
        return (
          <Typography
            className={`tab-label ${index === value ? "active" : ""}`}
          >
            <InterestIcon alt="repport-icon" /> Report Requests
          </Typography>
        );
      case 2:
        return (
          <Typography
            className={`tab-label ${index === value ? "active" : ""}`}
          >
            <RepportIcon alt="submmit-icon" /> Submitted Reports
          </Typography>
        );
      case 3:
        return (
          <Typography
            className={`tab-label ${index === value ? "active" : ""}`}
          >
            <CommentIcon alt="comments-icon" /> Comments
          </Typography>
        );
      default:
        return "";
    }
  }

  return (
    <Box className="bounty-tabs">
      <Box className="tabs-actions">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab label={labelProp(0)} {...a11yProps(0)} />
          <Tab label={labelProp(1)} {...a11yProps(1)} />
          <Tab label={labelProp(2)} {...a11yProps(2)} />
          <Tab label={labelProp(3)} {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <BountyAnalyticBox />
        <BountyRankingBox />
        <Box className="bounty-details-footer">
          <Box className="footer-text">
            <Typography>Ready to Dive In?</Typography>
            <Typography>
              If this project catches your eye, hit ‘Show Interest’ to get
              started and let the hacking begin! 👾🔍
            </Typography>
          </Box>
          <Box className="footer-divider" />
          <Button variant="outlined" className={`interest-btn`}>
            Send Interest
            <InterestIcon alt="interest-icon" />
          </Button>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <ReportRequestStep />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <ReportsList />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Comments comments={comments} />
      </CustomTabPanel>
    </Box>
  );
}
