import {
  Box,
  Button,
  Card,
  IconButton,
  InputBase,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

import filter_icon from "../../assets/icons/filter-icon.svg";
import export_icon from "../../assets/icons/export-icon.svg";

export default function OverviewTable() {
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <Card className="discoveries">
      <Typography
        variant="h3"
        sx={{ color: "#717575", fontWeight: 600, fontSize: "16px" }}
      >
        Overview Table
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{ width: "100%" }}
      >
        <Box
          className="notifs-search-container"
          sx={{ width: "45%", maxWidth: "600px" }}
        >
          <SearchIcon color="#717575" />
          <InputBase
            className="notifs-search-input"
            placeholder="Search by Project or Username"
            inputProps={{ "aria-label": "search" }}
            sx={{ flexGrow: 1 }}
          />
          <IconButton
            onClick={() => {
              setFilterOpen(!filterOpen);
            }}
          >
            <img src={filter_icon} alt="filter-icon" />
          </IconButton>
        </Box>
        <Button className="export-btn">
          <img src={export_icon} alt="export-icon" />
          <Typography sx={{display: {xs: "none", md: "block"} }}>Export</Typography>
        </Button>
      </Box>
      <TableContainer component={Paper} className="overview-table-container">
        <Table
          sx={{ minWidth: 650, textAlign: "center" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell>Report Title</TableCell>
              <TableCell align="right">Program</TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">CVSS</TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {[1, 2, 3].map((row, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="right">My report 1</TableCell>
                <TableCell align="right">Teklab</TableCell>
                <TableCell align="right">December 16, 2024</TableCell>
                <TableCell align="right">CVSS</TableCell>
                <TableCell align="right">Approved</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  );
}
