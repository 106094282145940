import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormGroup,
  Button,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import arrow_down from "../../assets/icons/arrow-down.svg";
import "../../styles/components/filter.css";

const FilterComponent = ({ isDate }) => {
  const [filters, setFilters] = useState({
    programFeatures: {
      CIDR: false,
      Domain: false,
      Hardware: false,
      Retesting: false,
      "Managed by Teklabspace": false,
      Playstore: false,
      "Source code": false,
      AWS: false,
      Wildcard: false,
      "Smart contracts": false,
    },
    selectedOS: [],
  });
  const [osAnchorEl, setOsAnchorEl] = useState(null);
  const openOSMenu = Boolean(osAnchorEl);

  const handleFeatureChange = (event) => {
    const { name, checked } = event.target;
    setFilters((prev) => ({
      ...prev,
      programFeatures: {
        ...prev.programFeatures,
        [name]: checked,
      },
    }));
  };

  const handleOSMenuOpen = (event) => {
    setOsAnchorEl(event.currentTarget);
  };

  const handleOSMenuClose = () => {
    setOsAnchorEl(null);
  };

  const handleOSChange = (event) => {
    const { value } = event.target;
    setFilters((prev) => ({
      ...prev,
      selectedOS: prev.selectedOS.includes(value)
        ? prev.selectedOS.filter((os) => os !== value)
        : [...prev.selectedOS, value],
    }));
  };

  const clearFilters = () => {
    setFilters({
      programFeatures: {
        CIDR: false,
        Domain: false,
        Hardware: false,
        Retesting: false,
        "Managed by Teklabspace": false,
        Playstore: false,
        "Source code": false,
        AWS: false,
        Wildcard: false,
        "Smart contracts": false,
      },
      selectedOS: [],
    });
  };

  return (
    <Box className="filter-container">
      <Typography variant="h6" sx={{ fontSize: "12px", mb: 1 }}>
        Filter :
      </Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        mb={2}      >
        <Box className="filter-header">
          <Button className="filter-tab active">Program features</Button>
          <Button className="filter-tab">By Asset type</Button>
        </Box>
        <Typography
          variant="button"
          className="clear-filter"
          onClick={clearFilters}
        >
          X Clear filter
        </Typography>
      </Box>

      <FormGroup>
        <Box className="filter-options">
          {Object.keys(filters.programFeatures).map((feature) => (
            <Box
              className={`filter-option ${
                filters.programFeatures[feature] && "checked"
              }`}
              key={feature}
            >
              <Checkbox
                className="checkbox"
                checked={filters.programFeatures[feature]}
                onChange={handleFeatureChange}
                name={feature}
              />
              {feature}
            </Box>
          ))}
        </Box>
      </FormGroup>
      <Box display="flex">
        <Box className="filter-os">
          <Typography variant="body1" sx={{ fontSize: "12px", mb: 1 }}>
            OS Type
          </Typography>
          <Box
            variant="button"
            className="os-select"
            onClick={handleOSMenuOpen}
          >
            <Typography variant="body1" sx={{ fontSize: "12px" }}>
              {filters.selectedOS.length > 0
                ? filters.selectedOS.join(", ")
                : "Select OS"}
            </Typography>
            <img src={arrow_down} alt="arrow_down" />
          </Box>
          <Menu
            anchorEl={osAnchorEl}
            open={openOSMenu}
            onClose={handleOSMenuClose}
          >
            {["Windows", "Linux", "MacOS"].map((os) => (
              <MenuItem key={os}>
                <Box className="os-option">
                  <Typography variant="body1" sx={{ fontSize: "12px" }}>
                    {os}
                  </Typography>
                  <Checkbox
                    checked={filters.selectedOS.includes(os)}
                    onChange={() => handleOSChange({ target: { value: os } })}
                    sx={{
                      color: filters.selectedOS.includes(os)
                        ? "#a633c0 !important"
                        : "",
                    }}
                  />
                </Box>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        {isDate && (
          <Box className="filter-os" ml={{ xm: 0, sm: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Typography variant="body1" sx={{ fontSize: "12px", mb: 1 }}>
                Date Range
              </Typography>
              <Box className="select-range" components={["DatePicker"]}>
                <DatePicker label="select date" className="date-picker" />
                <Box className="divider" />
                <DatePicker label="select date" className="date-picker" />
              </Box>
            </LocalizationProvider>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default FilterComponent;
