import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Card,
  Chip,
  Divider,
  Typography,
} from "@mui/material";

import "../../styles/components/discoverycard.css";
import { FavoriteBorderOutlined } from "@mui/icons-material";

import user_group from "../../assets/icons/user-group.svg";
import fork_icon from "../../assets/icons/fork-icon.svg";
import { ReactComponent as ExporeIcon } from "../../assets/icons/explore-icon.svg";
import user_icon from "../../assets/icons/user_default.png";
import { ReactComponent as FlagIcon } from "../../assets/icons/flag_icon.svg";
import { Link } from "react-router-dom";

export default function BountyLisItem({
  icon,
  title,
  minPrice,
  maxPrice,
  users,
  fork,
  description,
  tags,
}) {
  return (
    <Card className="discovery bounty-item">
      <Box className="bounty-item-body">
        <Box
          display="flex"
          justifyContent={{ xs: "space-between", md: "start" }}
          alignItems="center"
        >
          <Box className="icon-container">
            <img src={icon} alt="icon" />
          </Box>
          <Typography
            sx={{
              fontWeight: 600,
              color: "#57A885",
              display: { xs: "inline", md: "none" },
            }}
          >
            {minPrice} - {maxPrice}
          </Typography>
          <Box
            mx={3}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              display: { xs: "none", md: "inline" },
            }}
          >
            <Typography
              sx={{
                fontWeight: 600,
                color: "#333333",
              }}
            >
              {title}
            </Typography>
            <Box ml={2} className="favorite-icon">
              <FavoriteBorderOutlined />
            </Box>
          </Box>
        </Box>
        <Box
          display={{ xs: "flex", md: "none" }}
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography
            sx={{
              fontWeight: 600,
              color: "#333333",
            }}
          >
            {title}
          </Typography>
          <Box className="favorite-icon">
            <FavoriteBorderOutlined />
          </Box>
        </Box>

        <Box className="description-container">
          <Typography variant="body1" className="description">
            {description}
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              color: "#57A885",
              display: { xs: "none", md: "inline" },
            }}
          >
            {minPrice} - {maxPrice}
          </Typography>
        </Box>
        <Box className="description-container">
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            className="description"
          >
            <Box className="mini-box" my={1}>
              <img
                src={user_group}
                alt="user group icon"
                width={13}
                height={13}
              />
              <Typography variant="body2">{users}</Typography>
            </Box>
            <Box className="mini-box">
              <img src={fork_icon} alt="fork icon" width={13} height={13} />
              <Typography variant="body2">{fork}</Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" className="descrption">
            <Typography variant="body1" className="status-box">
              Company Approval Status:
            </Typography>
            <Chip
              icon={<FlagIcon />}
              label="Approved"
              variant="filled"
              className="status"
            />
          </Box>
        </Box>
        <Box className="description-container">
          <Box display="flex" justifyContent="start" alignItems="center">
            {tags?.map((tag, index) => (
              <Box key={index} className="mini-box" my={1}>
                <Typography variant="body2">{tag}</Typography>
              </Box>
            ))}
          </Box>
          <AvatarGroup total={200} className="avatar-group">
            <Avatar alt="Remy Sharp" src={user_icon} className="avatar" />
            <Avatar alt="Travis Howard" src={user_icon} />
            <Avatar alt="Agnes Walker" src={user_icon} />
            <Avatar alt="Trevor Henderson" src={user_icon} />
          </AvatarGroup>
        </Box>
      </Box>
      <Divider sx={{ m: 1 }} />
      <Box className="explore-btn-container">
        <Link to={`/bounties/${title}`} width="100% !important" >
          <Button variant="outlined" className="explore-btn">
            <Typography variant="body2">Explore</Typography>
            <ExporeIcon alt="expore icon" width={13} height={13} />
          </Button>
        </Link>
      </Box>
    </Card>
  );
}
