import { Box, Typography } from "@mui/material";

import "../../styles/dashboard.css";
import HackerLayout from "../../componants/layout/HackerLayout";
import OverviewTable from "../../componants/dashboard/OverviewTable";
import DashboardCharts from "../../componants/dashboard/DashboardCharts";
import FilterComponent from "../../componants/filter/FilterComponent";

import dash_icon from "../../assets/icons/dashboard-icon.svg";

const DashboardPage = () => {
  return (
    <HackerLayout>
      {({ filterOpen, setFilterOpen }) => (
        <Box px={3}>
          <Box display="flex" mb={5} sx={{}}>
            <img src={dash_icon} alt="dashboard-icon" />
            <Typography
              variant="h3"
              ml={1}
              sx={{ fontWeight: 600, fontSize: "16px" }}
            >
              Reports Dashboard
            </Typography>
          </Box>
          <Box className="balance-card header-balance-card">
            <Box>
              <Typography className="balance-title" sx={{ color: "#f2f2f2" }}>
                Wallet Balance
              </Typography>
              <Typography className="balance-value" sx={{ color: "#f2f2f2" }}>
                $27,005.43
              </Typography>
            </Box>
          </Box>
          {filterOpen && <FilterComponent />}
          <DashboardCharts />

          <OverviewTable />
        </Box>
      )}
    </HackerLayout>
  );
};

export default DashboardPage;
