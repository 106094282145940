import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import Button from "@mui/material/Button";
import { LockOutlined } from "@mui/icons-material";
import Typography from "@mui/material/Typography";
import { Autocomplete, Divider, Switch, TextField } from "@mui/material";

import { ReactComponent as RepportIcon } from "../../assets/icons/repport-icon.svg";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/arrow-down.svg";

const assetType = ["assetType 1", "assetType 2", "assetType 3"]; // asset Type menu options
const weaknessTypes = ["weakness 1", "weakness 2", "weakness 3"]; // Weakness type menu option
const steps = [
  {
    id: 0,
    label: "Asset Selection",
    title: "Choose Assets for Report Submission",
    description: ``,
  },
  {
    id: 1,
    label: "Weakness",
    title: "Identify & Choose Vulnerability Type",
    description: "",
  },
  {
    id: 2,
    label: "Severity",
    title: "Set Estimate for the severity of the issue",
    description: ``,
  },
  {
    id: 3,
    label: "Proof Of Concept",
    title: "🔍 Proof of Concept (PoC) - Key Section! 🔍",
    description: `Demonstrate your findings here. Upload clear, detailed evidence of the vulnerability, including screenshots, video clips, or code snippets. This is where you showcase exactly how the weakness can be reproduced—be as precise as possible to validate your report!`,
  },
];

export default function ReportRequestStepper() {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleStep = (index) => {
    setActiveStep(index);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const getstepContent = (index) => {
    return (
      <Box className="step-content">
        <Typography className="title">{steps[index]?.label}</Typography>
        <Typography>{steps[index]?.title}</Typography>
        <Typography className="description">
          {index === 3 && steps[index]?.description}
        </Typography>
        <Divider sx={{ width: "100%", my: 2 }} />
        {index === 0 && (
          <Autocomplete
            className="search-select"
            options={assetType}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Asset Type"
                placeholder="Search"
              />
            )}
            sx={{ width: "100%" }}
          />
        )}
        {index === 1 && (
          <Autocomplete
            className="search-select"
            options={weaknessTypes}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select weakness type"
                placeholder="Search"
              />
            )}
            sx={{ width: "100%" }}
          />
        )}
        {index === 2 && (
          <Box className="switch-wrapper">
            <Box className="switch-container">
              <Typography className="action-label">
                Do you want to add an Estimate to your report?
              </Typography>
              <Switch aria-label="estimate" defaultChecked />
            </Box>
            <Box className="">
              <Typography className="label-text">
                Severity Calculation Method
              </Typography>
              <Autocomplete
                className="search-select"
                options={weaknessTypes}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select weakness type"
                    placeholder="Search"
                  />
                )}
                sx={{ width: "100%" }}
              />
              <Typography className="label-text">
                CVSS is the common vulnerabilty scoring system
              </Typography>
            </Box>
            <Box className="attribute-container ">
              <Box className="bounty-attribute">
                <Typography className="attribute-label">
                  Attack vector
                </Typography>
                <Box className="actions-btn-container">
                  <Button onClick={() => {}}>Network</Button>
                  <Button onClick={() => {}}>Local</Button>
                  <Button onClick={() => {}}>Adjacent</Button>
                  <Button className={`${"active"}`} onClick={() => {}}>
                    Physical
                  </Button>
                </Box>
              </Box>
              <Box className="bounty-attribute">
                <Typography className="attribute-label">
                  Attack Complexity
                </Typography>
                <Box className="actions-btn-container">
                  <Button className={`${"active"}`} onClick={() => {}}>
                    Low
                  </Button>
                  <Button onClick={() => {}}>High</Button>
                </Box>
              </Box>
              <Box className="bounty-attribute">
                <Typography className="attribute-label">
                  Privileges Required
                </Typography>
                <Box className="actions-btn-container">
                  <Button className={`${"active"}`} onClick={() => {}}>
                    None
                  </Button>
                  <Button onClick={() => {}}>Low</Button>
                  <Button onClick={() => {}}>High</Button>
                </Box>
              </Box>
              <Box className="bounty-attribute">
                <Typography className="attribute-label">
                  User Interaction
                </Typography>
                <Box className="actions-btn-container">
                  <Button onClick={() => {}}>None</Button>
                  <Button className={`${"active"}`} onClick={() => {}}>
                    Required
                  </Button>
                </Box>
              </Box>
              <Box className="bounty-attribute">
                <Typography className="attribute-label">Scope</Typography>
                <Box className="actions-btn-container">
                  <Button className={`${"active"}`} onClick={() => {}}>
                    Unchanged
                  </Button>
                  <Button onClick={() => {}}>Changed</Button>
                </Box>
              </Box>
              <Box className="bounty-attribute">
                <Typography className="attribute-label">
                  Confidentiality
                </Typography>
                <Box className="actions-btn-container">
                  <Button onClick={() => {}}>None</Button>
                  <Button onClick={() => {}}>Low</Button>
                  <Button className={`${"active"}`} onClick={() => {}}>
                    High
                  </Button>
                </Box>
              </Box>
              <Box className="bounty-attribute">
                <Typography className="attribute-label">Integrity</Typography>
                <Box className="actions-btn-container">
                  <Button onClick={() => {}}>None</Button>
                  <Button onClick={() => {}}>Low</Button>
                  <Button className={`${"active"}`} onClick={() => {}}>
                    High
                  </Button>
                </Box>
              </Box>
              <Box className="bounty-attribute">
                <Typography className="attribute-label">
                  Availability Impact
                </Typography>
                <Box className="actions-btn-container">
                  <Button onClick={() => {}}>None</Button>
                  <Button onClick={() => {}}>Low</Button>
                  <Button className={`${"active"}`} onClick={() => {}}>
                    High
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {index === 3 && (
          <Box sx={{ width: "100%" }}>
            <Box>
              <Typography className="label-text">Title</Typography>
              <TextField
                className="search-select"
                placeholder="POC title"
                sx={{ width: "100%", fontSize: "12px" }}
              />
            </Box>
            <Box mt={3}>
              <Typography className="label-text">Description !</Typography>
              <Typography className="label-text">
                Explain the vulnerability, impact, and steps to reproduce.
                Clear, concise, and detailed for faster review !
              </Typography>
              <Box className="text-area-container">
                <Box className="area-actions">
                  <Button className={`${"active"}`}>write</Button>
                  <Button>preview</Button>
                </Box>
                <textarea
                  id="text-area-container"
                  placeholder="Company Policy here"
                  className="area-input"
                  multiline
                  rows={4}
                />
              </Box>
              <Typography className="label-text">
                Styling with Markdown supported
              </Typography>
            </Box>

            <Box mt={3}>
              <Typography className="label-text">Impact:</Typography>
              <Typography className="label-text">
                Explain the Security Impact of your findings
              </Typography>
              <Box className="text-area-container">
                <Box className="area-actions">
                  <Button className={`${"active"}`}>write</Button>
                  <Button>preview</Button>
                </Box>
                <textarea
                  id="text-area-container"
                  placeholder="Company Policy here"
                  className="area-input"
                  multiline
                  rows={4}
                />
              </Box>
              <Typography className="label-text">
                Styling with Markdown supported
              </Typography>
            </Box>
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box className="send-request">
      <Box className="discovery">
        <Box className="submit-request-header">
          <Box display="flex" alignItems="center">
            <RepportIcon alt="repport-icon" /> {"   "}
            <Typography ml={1} className={`tab-label`}>
              Report Submisson
            </Typography>
          </Box>
          <ArrowDownIcon alt="arrow-icon" />
        </Box>
        <Divider />
        <Stepper
          className="repport-stepper"
          activeStep={activeStep}
          orientation="vertical"
        >
          {steps.map((step, index) => (
            <Step key={step.label}>
              <Box>
                <StepLabel
                  StepIconComponent={activeStep !== index && LockOutlined}
                >
                  {index !== activeStep && (
                    <Box
                      className="step-label"
                      onClick={() => handleStep(step.id)}
                    >
                      <Typography className="title">{step.label}</Typography>
                    </Box>
                  )}
                </StepLabel>
                <StepContent>{getstepContent(index)}</StepContent>
              </Box>
            </Step>
          ))}
        </Stepper>
      </Box>
      {activeStep === 3 && (
        <>
          <Divider sx={{ width: "100%", mt: 6 }} />
          <Box className="bounty-details-footer report-submission">
            <Box className="footer-text">
              <Typography>Almost There! </Typography>
              <Typography>
                if you’re not ready to submit just yet, hit ‘Save draft’ and
                come back anytime to finish up. Ready to lock it in? Click
                ‘Submit Report’ Happy hacking! 👾🔍
              </Typography>
            </Box>
            <Box className="footer-divider" />
            <Button
              variant="outlined"
              className={`interest-btn`}
              onClick={handleReset}
            >
              Save as Draft
            </Button>
            <Button
              variant="outlined"
              className={`interest-btn submit`}
              onClick={handleReset}
            >
              Submit Report
              <RepportIcon alt="report-icon" />
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}
