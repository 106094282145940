import { Box } from "@mui/material";
import Appbar from "../AppBar/AppBar";
import HackerNavbar from "../Navbar/HackerNavbar";
import { useState } from "react";

const HackerLayout = ({ children }) => {
  const [filterOpen, setFilterOpen] = useState(false);

  return (
    <Box className="full-page">
      <HackerNavbar />
      <Box className="page-container">
        <Appbar filterOpen={filterOpen} setFilterOpen={setFilterOpen} />
        <Box className="page-content">
          {children({ filterOpen, setFilterOpen })}
        </Box>
      </Box>
    </Box>
  );
};

export default HackerLayout;
