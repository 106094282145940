import { Box, Card, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { ChartContainer } from "@mui/x-charts/ChartContainer";
import { BarPlot } from "@mui/x-charts/BarChart";
import { LineChart } from "@mui/x-charts/LineChart";

import tree_icon from "../../assets/icons/tree-icon.svg";

const uData = [4000, 3000, 2000, 2780, 1890, 2390, 3490];
const xLabels = [
  "Page A",
  "Page B",
  "Page C",
  "Page D",
  "Page E",
  "Page F",
  "Page G",
];

export default function DashboardCharts() {
  return (
    <Card className="discoveries">
      <Grid className="discoveries-items" container spacing={2}>
        {[1, 2, 3].map((_, index) => (
          <Grid size={{ xs: 12, md: 6, lg: 4 }}>
            <Box key={index} className="balance-card">
              <Typography className="balance-title">Wallet Balance</Typography>
              <Typography className="balance-value">$27,005.43</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Divider sx={{ width: "100%" }} />
      <Grid className="reports-stats" container spacing={2}>
        {[1, 2, 3].map((_, index) => (
          <Grid key={index} size={{ xs: 12, md: 6, lg: 4 }}>
            <Box className="stat">
              <img src={tree_icon} alt="tree-icon" />
              <Typography className="balance-title">
                Accepted Invitations
              </Typography>
              <Typography className="balance-value"> 300</Typography>
            </Box>
          </Grid>
        ))}
      </Grid>
      <Grid className="reports-charts" container spacing={2}>
        <Grid size={{ xs: 12, md: 6, lg: 4 }} className="chart">
          <Box>
            <Typography className="balance-title">Program Type</Typography>
          </Box>
          <ChartContainer
            width={300}
            height={300}
            series={[{ data: uData, label: "uv", type: "bar" }]}
            xAxis={[{ scaleType: "band", data: xLabels }]}
            borderRadius={30}
            colors={["#333333"]}
          >
            <BarPlot />
          </ChartContainer>
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 4 }} className="chart">
          <Box>
            <Typography className="balance-title">Acceptance Rate</Typography>
          </Box>

          <LineChart
            xAxis={[{ data: [1, 2, 3, 5, 8, 10], colors: ["#333333"] }]}
            series={[
              {
                data: [2, 5.5, 2, 8.5, 1.5, 5],
                area: true,
              },
            ]}
            width={300}
            height={300}
            slotProps={{ legend: { hidden: true } }}
            colors={["#333333"]}
          />
        </Grid>
        <Grid size={{ xs: 12, md: 6, lg: 4 }} className="chart">
          <Box>
            <Typography className="balance-title">
              Status Distribution
            </Typography>
          </Box>
          <LineChart
            xAxis={[{ data: [1, 2, 3, 5, 8, 10] }]}
            series={[
              {
                data: [2, 5.5, 2, 8.5, 1.5, 5],
                area: true,
              },
            ]}
            width={300}
            height={300}
            colors={["#333333"]}
          />
        </Grid>
      </Grid>
    </Card>
  );
}
